import React, { Component } from "react";
import {
  Button,
  Grid,
  Header,
  Popup
} from 'semantic-ui-react'


class Hint extends Component {

  render() {
    return (

      <Popup trigger={<Button>Help!!!</Button>} flowing hoverable inverted>
        <Grid>
          <Grid.Column>
            <Header as='h4'>Group 1</Header>
            <p>
              <b>う　。　つ　。　る　→　</b> って
            </p>
            <p>
              <b>む　。　ぬ　。　ぶ　→　</b> んで
            </p>
            <p>
              <b>く　→　</b> いて
            </p>
            <p>
              <b>ぐ　→　</b> いで
            </p>
            <p>
              <b>す　→　</b> して
            </p>
            <p>
              <b>* Exception</b> いく　→　いって
            </p>

            <Header as='h4'>Group 2</Header>
            <p>
              Just change る to て
            </p>

            <Header as='h4'>Group 3</Header>
            <p>
              くる　→　きて
            </p>
            <p>
              する　→　して
            </p>


          </Grid.Column>

        </Grid>
      </Popup>

    );
  }


}

export default Hint;
