import React from 'react';
import QuestionDashboard from './QuestionDashboard';
import {
  Container,
  Grid,
  Menu,
  Statistic,
} from 'semantic-ui-react'
import './App.css';
import Hint from './Hint';

window.$verbs = [];

window.$verbs.push({group: "1", kanji:"書く", verb:"かく", meaning:"To Write", form: ["かかない","かいた","かかなかった","かいて"] });
window.$verbs.push({group: "1", kanji:"聞く", verb:"きく", meaning:"To Listen", form: ["きかない","きいた","きかなかった","きいて"] });
window.$verbs.push({group: "1", kanji:"着く", verb:"つく", meaning:"To Arrive", form: ["つかない","ついた","つかなかった","ついて"] });
window.$verbs.push({group: "1", kanji:"履く", verb:"はく", meaning:"To Wear/Put", form: ["はかない","はいた","はかなかった","はいて"] });
window.$verbs.push({group: "1", kanji:"置く", verb:"おく", meaning:"To Put/Place", form: ["おかない","おいた","おかなかった","おいて"] });
window.$verbs.push({group: "1", kanji:"歩く", verb:"あるく", meaning:"To Walk", form: ["あるかない","あるいた","あるかなかった","あるいて"] });
window.$verbs.push({group: "1", kanji:"磨く", verb:"みがく", meaning:"To Brush", form: ["みがかない","みがいた","みがかなかった","みがいて"] });
window.$verbs.push({group: "1", kanji:"働く", verb:"はたらく", meaning:"To Work", form: ["はたらかない","はたらいた","はたらかなかった","はたらいて"] });
window.$verbs.push({group: "1", kanji:"行く", verb:"いく", meaning:"To Go ", form: ["いかない","いった","いかなかった","いって"] });
window.$verbs.push({group: "1", kanji:"空く", verb:"すく", meaning:"To be Empty", form: ["すかない","すいた","すかなかった","すいて"] });

window.$verbs.push({group: "1", kanji:"脱ぐ", verb:"ぬぐ", meaning:"To Take Cloth Off", form: ["ぬがない","ぬいだ","ぬがなかった","ぬいで"] });
window.$verbs.push({group: "1", kanji:"泳ぐ", verb:"およぐ", meaning:"To Swim", form: ["およがない","およいだ","およがなかった","およいで"] });
window.$verbs.push({group: "1", kanji:"急ぐ", verb:"いそぐ", meaning:"To hurry", form: ["いそがない","いそいだ","いそがなかった","いそいで"] });

window.$verbs.push({group: "1", kanji:"貸す", verb:"かす", meaning:"To Lend", form: ["かさない","かした","かさなかった","かして"] });
window.$verbs.push({group: "1", kanji:"消す", verb:"けす", meaning:"To Turn Off", form: ["けさない","けした","けさなかった","けして"] });
window.$verbs.push({group: "1", kanji:"話す", verb:"はなす", meaning:"To Talk", form: ["はなさない","はなした","はなさなかった","はなして"] });
window.$verbs.push({group: "1", kanji:"返す", verb:"かえす", meaning:"To Give Back", form: ["かえさない","かえした","かえさなかった","かえして"] });
window.$verbs.push({group: "1", kanji:"探す", verb:"さがす", meaning:"To Look For", form: ["さがさない","さがした","さがさなかった","さがして"] });
window.$verbs.push({group: "1", kanji:"無くす", verb:"なくす", meaning:"To lose", form: ["なくさない","なくした","なくさなかった","なくして"] });
// window.$verbs.push({group: "1", kanji:"思い出します", verb:"おもいだします", meaning:"To Recall / Remember", form: ["","","","",""] });
window.$verbs.push({group: "1", kanji:"落とす ", verb:"おとす", meaning:"To Drop", form: ["おとさない","おとした","おとさなかった","おとして"] });
window.$verbs.push({group: "1", kanji:"直す", verb:"なおす", meaning:"To repair / Fix", form: ["なおさない","なおした","なおさなかった","なおして"] });

// nimasu
window.$verbs.push({group: "1", kanji:"死ぬ ", verb:"しぬ", meaning:"To Die", form: ["しなない","しんだ","しななかった","しんで"] });

//mimasu
window.$verbs.push({group: "1", kanji:"飲む", verb:"のむ", meaning:"To Drink", form: ["のまない","のんだ","のまなかった","のんで"] });
window.$verbs.push({group: "1", kanji:"読む", verb:"よむ", meaning:"To Read", form: ["よまない","よんだ","よまなかった","よんで"] });
window.$verbs.push({group: "1", kanji:"住む", verb:"すむ", meaning:"To Live", form: ["すまない","すんだ","すまなかった","すんで"] });
window.$verbs.push({group: "1", kanji:"込む", verb:"こむ", meaning:"To Be Crowded", form: ["こまない","こんだ","こまなかった","こんで"] });
window.$verbs.push({group: "1", kanji:"休む", verb:"やすむ", meaning:"To Have a Break", form: ["やすまない","やすんだ","やすまなかった","やすんで"] });
window.$verbs.push({group: "1", kanji:"頼む", verb:"たのむ", meaning:"To Request", form: ["たのまない","たのんだ","たのまなかった","たのんで"] });

// bimasu
window.$verbs.push({group: "1", kanji:"呼ぶ", verb:"よぶ", meaning:"To Call", form: ["よばない","よんだ","よばなかった","よんで"] });
window.$verbs.push({group: "1", kanji:"遊ぶ", verb:"あそぶ", meaning:"To Play", form: ["あそばない","あそんだ","あそばなった","あそんで"] });
window.$verbs.push({group: "1", kanji:"選ぶ", verb:"えらぶ", meaning:"To Choose", form: ["えらばない","えらんだ","えらばなかった","えらんで"] });
window.$verbs.push({group: "1", kanji:"運ぶ", verb:"はこぶ", meaning:"To Carry", form: ["はこばない","はこんだ","はこばなかった","はこんで"] });
window.$verbs.push({group: "1", kanji:"転ぶ", verb:"ころぶ", meaning:"To Fall Over", form: ["ころばない","ころんだ","ころばなかった","ころんで"] });
window.$verbs.push({group: "1", kanji:"喜ぶ", verb:"よろこぶ", meaning:"To Be Glad", form: ["よろこばない","よろこんだ","よろこばなかった","よろこんで"] });

window.$verbs.push({group: "1", kanji:"立つ", verb:"たつ", meaning:"To Stand", form: ["たたない","たった","たたなかった","たって"] });
window.$verbs.push({group: "1", kanji:"待つ", verb:"まつ", meaning:"To Wait For", form: ["またない","まった","またなかった","まって"] });
window.$verbs.push({group: "1", kanji:"持つ", verb:"もつ", meaning:"To Hold", form: ["もたない","もった","もたなかった","もって"] });

window.$verbs.push({group: "1", kanji:"買う", verb:"かう", meaning:"To Buy", form: ["かわない","かった","かわなかった","かって"] });
window.$verbs.push({group: "1", kanji:"会う", verb:"あう", meaning:"To Meet", form: ["あわない","あった","あわなかった","あって"] });
window.$verbs.push({group: "1", kanji:"言う", verb:"いう", meaning:"To Say", form: ["いわない","いった","いわなかった","いって"] });
window.$verbs.push({group: "1", kanji:"吸う", verb:"すう", meaning:"To Smoke", form: ["すわない","すった","すわなかった","すって"] });
window.$verbs.push({group: "1", kanji:"歌う", verb:"うたう", meaning:"To Sing", form: ["うたわない","うたった","うたわなかった","うたって"] });
window.$verbs.push({group: "1", kanji:"使う", verb:"つかう", meaning:"To Use", form: ["つかわない","つかった","つかわなかった","つかって"] });
window.$verbs.push({group: "1", kanji:"習う", verb:"ならう", meaning:"To Learn", form: ["ならわない","ならった","ならわなかった","ならって"] });
window.$verbs.push({group: "1", kanji:"払う", verb:"はらう", meaning:"To Pay", form: ["はらわない","はらった","はらわなかった","はらって"] });
window.$verbs.push({group: "1", kanji:"笑う", verb:"わらう", meaning:"To Laugh", form: ["わらわない","わらった","わらわなかった","わらって"] });
window.$verbs.push({group: "1", kanji:"洗う", verb:"あらう", meaning:"To Wash", form: ["あらわない","あらった","あらわなかった","あらって"] });
window.$verbs.push({group: "1", kanji:"拾う", verb:"ひろう", meaning:"To Pick up", form: ["ひろわない","ひろった","ひろわなかった","ひろって"] });
window.$verbs.push({group: "1", kanji:"貰う", verb:"もらう", meaning:"To Receive", form: ["もらわない","みらった","もらわなかった","みらって"] });
window.$verbs.push({group: "1", kanji:"誘う", verb:"さそう", meaning:"To Invite", form: ["さそわない","さそった","さそわなかった","さそって"] });
window.$verbs.push({group: "1", kanji:"手伝う", verb:"てつだう", meaning:"To Help", form: ["てつだわない","てつだった","てつだわなかった","てつだって"] });


window.$verbs.push({group: "1", kanji:"売る", verb:"うる", meaning:"To Sell", form: ["うらない","うった","うらなかった","うって"] });
window.$verbs.push({group: "1", kanji:"取る", verb:"とる", meaning:"To Take", form: ["とらない","とった","とらなかった","とって"] });
window.$verbs.push({group: "1", kanji:"乗る", verb:"のる", meaning:"To Ride", form: ["のらない","のった","のらなかった","のって"] });
window.$verbs.push({group: "1", kanji:"降る", verb:"ふる", meaning:"To Fall", form: ["ふらない","ふった","ふらなかった","ふって"] });
// window.$verbs.push({group: "1", kanji:"ある", verb:"ある", meaning:"To Exist", form: ["","","",""] });
window.$verbs.push({group: "1", kanji:"帰る", verb:"かえる", meaning:"To Return", form: ["かえらない","かえった","かえらなかった","かえって"] });
window.$verbs.push({group: "1", kanji:"走る", verb:"はしる", meaning:"To Run", form: ["はしらない","はしった","はしらなかった","はしって"] });
window.$verbs.push({group: "1", kanji:"座る", verb:"すわる", meaning:"To Sit Down", form: ["すわらない","すわった","すわらなかった","すわって"] });
window.$verbs.push({group: "1", kanji:"作る", verb:"つくる", meaning:"To Make", form: ["つくらない","つくった","つくらなかった","つくって"] });
window.$verbs.push({group: "1", kanji:"曲がる", verb:"まがる", meaning:"To Turn", form: ["まがらない","まがった","まがらなかった","まがって"] });
window.$verbs.push({group: "1", kanji:"送る", verb:"おくる", meaning:"To Send", form: ["おくらない","おくった","おくらなかった","おくって"] });
window.$verbs.push({group: "1", kanji:"要る", verb:"いる", meaning:"To Need", form: ["いらない","","いらなかった",""] }); //review
window.$verbs.push({group: "1", kanji:"怒る", verb:"おこる", meaning:"To Get Angry", form: ["おこらない","おこった","おこらなかった","おこって"] });
window.$verbs.push({group: "1", kanji:"止まる", verb:"とまる", meaning:"To Stop", form: ["とまらない","とまった","とまらなかった","とまって"] });
window.$verbs.push({group: "1", kanji:"分かる", verb:"わかる", meaning:"To Understand", form: ["わからない","わかった","わからなかった","わかって"] });
window.$verbs.push({group: "1", kanji:"太る", verb:"ふとる", meaning:"To Put Weight On", form: ["ふとらない","ふとった","ふとらなかった","ふとって"] });
window.$verbs.push({group: "1", kanji:"喋る", verb:"しゃべる", meaning:"To Chat", form: ["しゃべらない","しゃべった","しゃべらなかった","しゃべって"] });
window.$verbs.push({group: "1", kanji:"断わる", verb:"ことわる", meaning:"To Refuse", form: ["ことわらない","ことわった","ことわらなかった","ことわって"] });
window.$verbs.push({group: "1", kanji:"入る", verb:"はいる", meaning:"To Enter", form: ["はいらない","はいった","はいらなかった","はいって"] });
window.$verbs.push({group: "1", kanji:"終わる", verb:"おわる", meaning:"To Finish", form: ["おわらない","おわった","おわらなかった","おわって"] });

window.$verbs.push({group: "2", kanji:"寝る", verb:"ねる", meaning:"To Sleep", form: ["ねない","ねた","ねなかった","ねて"] });
window.$verbs.push({group: "2", kanji:"食べる", verb:"たべる", meaning:"To Eat", form: ["たべない","たべた","たべなかった","たべて"] });
window.$verbs.push({group: "2", kanji:"見せる", verb:"みせる", meaning:"To Show", form: ["めせない","みせた","みせなかった","みせて"] });
window.$verbs.push({group: "2", kanji:"点ける", verb:"つける", meaning:"To Turn On", form: ["つけない","つけた","つけなかった","つけて"] });
window.$verbs.push({group: "2", kanji:"あげる", verb:"あげる", meaning:"To Give", form: ["あげない","","あげなかった","あげて"] });
// window.$verbs.push({group: "2", kanji:"", verb:"", meaning:"To Give to me", form: ["","","",""] });
window.$verbs.push({group: "2", kanji:"開ける", verb:"あける", meaning:"To Open", form: ["あけない","あけて","あけなかった","あけて"] });
window.$verbs.push({group: "2", kanji:"閉める", verb:"しめる", meaning:"To Shut", form: ["しめない","しめた","しめなかった","しめて"] });
window.$verbs.push({group: "2", kanji:"入れる", verb:"いれる", meaning:"To Put In", form: ["いれない","いれた","いれなかった","いれて"] });
window.$verbs.push({group: "2", kanji:"出る", verb:"でる", meaning:"To Leave", form: ["でない","でた","でなかった","でて"] });
window.$verbs.push({group: "2", kanji:"教える", verb:"おしえる", meaning:"To Teach", form: ["おしえない","おしえた","おしえなかった","おしえて"] });
window.$verbs.push({group: "2", kanji:"止める", verb:"とめる", meaning:"To Stop Something", form: ["とめない","とめた","とめなかった","とめて"] });


// window.$verbs.push({group: "2", kanji:"", verb:"", meaning:"", form: ["","","",""] });


window.$form = ["Negative","Past (た)", "Past Negative", "て"];


class App extends React.Component {
  constructor(props) {
    super(props);

    const question = this.getQuestion();

    this.state =
      {
        question,
        correct: null,
        answer: "",
        correctCount: 0,
        totalCount: 0,
    };

  }

  getQuestion = () => {
    const verb = this.getVerb();
    const formIndex = this.getForm();

    return {
      verbForm: window.$form[formIndex],
      verbAnswer: verb.form[formIndex],
      verb: verb.verb,
      verbMeaning: verb.meaning,
      group: verb.group,
      kanji: verb.kanji
    }
  }

  getVerb = () => {

    const verbsIndex = Math.floor(Math.random() * (window.$verbs.length));
    return window.$verbs[verbsIndex];
  }

  getForm = () => {
    return  Math.floor(Math.random() * (4));
  }

  handleAnswer = (isCorrect) => {

    let count = this.state.correctCount;
    if(isCorrect){
      count++
    }

    this.setState({
      correctCount: count,
      totalCount: this.state.totalCount+= 1},
    () => {

    })
  }

  handleNewQuestion = () => {

    const question = this.getQuestion();

    this.setState(
      {
        question: question,
        correct: null,
        answer: null
    },
    () => {

    });
  }

  getPct(correct , total){
    const numb = correct / total;
    let pct = 0;

    if(numb.isNaN){
      alert("xxx");
    }

    return pct;
  }


  render() {

    return (
      <div>
      <Menu fixed='top' inverted>
        <Container>
          <Menu.Item as='a' header>
            Japanese Verbs
          </Menu.Item>
          <Menu.Item as='a'>Home</Menu.Item>
        </Container>
      </Menu>

      <Container text style={{ marginTop: '7em' }}>

      <Grid divided inverted stackable>
        <Grid.Column width={10}>
        </Grid.Column>
        <Grid.Column width={5}>
          <Statistic.Group items={[{ key: 'correct', label: 'Correct', value: this.state.correctCount },
                                  { key: 'total', label: 'Total', value: this.state.totalCount }]} />
        </Grid.Column>
        <Grid.Column width={15}>

          <QuestionDashboard  question={this.state.question}
                              handleAnswer={this.handleAnswer}
                              handleNewQuestion={this.handleNewQuestion} />

        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column width={15} align="right">
          <Hint />
        </Grid.Column>
      </Grid>

      </Container>


    </div>
    );

  }
}


export default App;
