import React, { Component } from "react";
import {
  Header,
  Input,
  Button,
  Message,
  Label,
  Icon,
  Form
} from 'semantic-ui-react'


class QuestionDashboard extends Component {
  constructor(props){
    super(props);

    this.state = {
      myState: true,
      question: {},
      answer: "",
      correct: null
    }
  }

  componentWillMount() {
    this.setState({
      question: this.props.question,
      correct: this.props.correct,
      handleAnswer: this.props.handleAnswer,
      handleNewQuestion: this.props.handleNewQuestion
    });
  }

  handleAnswer = () => {

    let isCorrect = false;
    if (this.state.answer === this.state.question.verbAnswer) {
      isCorrect = true;
    }

    this.setState({correct: isCorrect}, () => {
      this.state.handleAnswer(isCorrect);
    })

	}

  handleNewQuestion = () => {
    this.state.handleNewQuestion();
  }

  handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            answer: e.target.value,
        })
    }

  componentWillReceiveProps(props){

    if (this.state.question !== props.question){

      this.setState(
        {
          question: props.question,
          correct: null,
          answer: ""
      }, () => {
        this.state.answerInput.focus();
      });

    } else {
        this.state.btnNext.focus();
    }

  }

  componentDidMount(){

    this.setState({
      answerInput: this.answerInput,
      btnNext: this.btnNext
    }, () => {
      this.state.answerInput.focus();
    });
  }

  handleKeyDown = (e) => {
    if(e.key === "Enter"){
      this.handleAnswer();
    }
  }

  render() {

    return (
      <Message floating>
        <Header as='h5' floated='right'>
        <Label as='a'>
          <Icon name='archive' />
          Group {this.state.question.group}
        </Label>
        </Header>

        <Header as='h2'>What is the "{this.state.question.verbForm}" form of:</Header>
        <br/>
        <Form.Field>
          <Label pointing='below' style={{ fontSize: '1.5em' }}>{this.state.question.kanji}</Label>
          <Header as='h1' style={{ fontSize: '2.5em' }}>{this.state.question.verb}</Header>
        </Form.Field>

        <Header as='h3'>{this.state.question.verbMeaning}</Header>
        <br/>

        <Message success style={this.state.correct === true ? { } : {display: 'none'}}>
          <Header as='h3' style={{ fontSize: '2em' }}>
          {this.state.question.verbAnswer}
          </Header>
          <p>Is the {this.state.question.verbForm} form of {this.state.question.verb}</p>
        </Message>

        <Message negative style={this.state.correct === false ? { } : {display: 'none'}}>
          <Header as='h3' style={{ fontSize: '2em' }}>
            {this.state.question.verbAnswer}
          </Header>
          <p>Is the {this.state.question.verbForm} form of {this.state.question.verb}</p>

        </Message>

        <Input name='answer'
                ref={(input) => { this.answerInput = input; }}
                size='huge'
                placeholder='Answer(hiragana)'
                value={this.state.answer}
                onChange={e => this.handleChange(e)}
                style={this.state.correct != null ? {display: 'none'} : {}}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                onKeyDown={e => this.handleKeyDown(e)}
                />
        <br/><br/>

        <Button basic color='green'
                size='huge'
                content='Check Answer'
                onClick={this.handleAnswer}
                style={this.state.correct != null ? { display: 'none' } : {}} />

        <Button basic content='Next'
                ref={(btn) => { this.btnNext = btn; }}
                onClick={this.handleNewQuestion}
                size='huge'
                style={this.state.correct == null ? { display: 'none' } : {}} />


      </Message>
    );
  }
}

export default QuestionDashboard;
